import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class ToastService {
  isShown: boolean = false;
  message: string[] = [];
  timeout: number = 0;
  readonly icons = ['warning-fill', 'warning-fill', 'info-fill', 'success-fill'];
  readonly colors = ['error', 'warning', 'info', 'success'];
  icon = 'info-fill';
  color = 'var(--toast-info)';
  background = 'var(--toast-info-b)';

  public show(message: string, status: -2 | -1 | 0 | 1 = 0, durationMs = 6000): void {
    this.showLongText([message], status, durationMs);
  }

  public showError(message: string, e: any, durationMs?: number, type: -2 | -1 | 0 | 1 = -1): void {
    // console.clear();
    const reason = e.error?.message || e.message;
    const status = e.error?.statusCode || e.status;
    const messages = [message];
    if (reason) {
      messages.push(`Причина: ${ reason }`);
    }
    if (status) {
      messages.push('Код ошибки ' + status);
    }
    this.showLongText(messages, type, durationMs);
  }

  public showLongText(message: string[], status: -2 | -1 | 0 | 1 = 0, durationMs = 6000): void {
    this.hide();
    this.message = message;
    this.isShown = true;
    this.icon = this.icons[status + 2];
    this.color = `var(--toast-${ this.colors[status + 2] })`;
    this.background = `var(--toast-${ this.colors[status + 2] }-b)`;
    if (status <= -1) {
      try {
        window.navigator.vibrate(30);
      } catch (ignore) {
      }
    }
    this.timeout = window.setTimeout(() => {
      this.hide();
    }, durationMs);
  }

  public hide(): void {
    clearTimeout(this.timeout);
    this.isShown = false;
  }

}
