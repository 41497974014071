import { PartialMap } from './typesctipt.utils';

export interface PermissionRecord {
  userId: number;
  key: Permission;
  value: boolean;
}

export enum Permission {
  VIEW_STAFF = 1,
  EDIT_STAFF = 2,
  EDIT_SELF = 3,

  VIEW_STAFF_SCHEDULE = 4,
  EDIT_SELF_SCHEDULE = 5,
  EDIT_STAFF_SCHEDULE = 6,

  VIEW_ADMISSIONS = 7,
  EDIT_ADMISSIONS = 8,
  EDIT_SELF_ADMISSIONS = 9,
  CREATE_ADMISSIONS = 10,
  CREATE_SELF_ADMISSIONS = 11,

  VIEW_CLIENTS = 12,
  EDIT_CLIENTS = 13,

  VIEW_INTEGRATIONS = 14,
  MANAGE_INTEGRATIONS = 15,

  VIEW_ORDERS = 16,
  EDIT_ORDERS = 17,
  CREATE_ORDERS = 18,
  TAKE_ORDERS = 19,
  ORDER_CHANGE_ASSIGNEE = 20,
  MANAGE_WORKFLOW = 21,

  VIEW_EMAILS = 22,
  SEND_EMAILS = 23,

  MANAGE_PERMISSIONS = 24,
  SETTINGS = 25,
}

export type ACL = PartialMap<Permission, boolean>;

export type CompressedACL = string;

export const getPermissionsArray = (): Permission[] => Object.values(Permission)
  .filter(p => typeof p === 'number') as Permission[];

export const buildACL = (active: PermissionRecord[]): ACL => {
  return getPermissionsArray().reduce((all: ACL, p) => {
    all[p as Permission] = active.find(a => a.key == p)?.value ?? false;
    return all;
  }, {} as ACL);
};



const SEPARATOR = 'X';

export const compressACL = (acl: ACL): CompressedACL => {
  const binary = getPermissionsArray().map(p => acl[p as Permission] ? '1' : '0').join('');
  const leadingZerosCount = binary.match(/^0+/)?.[0]?.length ?? 0;
  return leadingZerosCount + SEPARATOR + parseInt(binary, 2);
};

export const parseACL = (c: CompressedACL): ACL => {
  const [zerosCount, permissions] = c.split(SEPARATOR).map(n => parseInt(n, 10));
  const binary = '0'.repeat(zerosCount) + permissions.toString(2);
  return getPermissionsArray().reduce((all: ACL, p, i: number) => {
    (binary.charAt(i) === '1') && (all[p] = true);
    return all;
  }, {} as ACL);
};
